import React from "react";
import ReactDOM from "react-dom";
import NewApp from "./NewApp";
import { ThemeProvider, GlobalStyle, spvNewTheme } from "@sparebankenvest/splash-ui";

import * as serviceWorker from "./serviceWorker";

//Test
/*
const theme = {
  ...spvNewTheme,
  splash: {
    ...spvNewTheme.splash,
    accordion: {
      ...spvNewTheme.splash.accordion,
        content: {
          ...spvNewTheme.splash.accordion.content,
          'background-color': 'pink',
        },
        item: {
          ...spvNewTheme.splash.accordion.item,
          'background-color': {
            ...spvNewTheme.splash.accordion.item['background-color'],
            hover: 'pink',
          },
        },
    },
  },
};*/

ReactDOM.render(
  <ThemeProvider theme={spvNewTheme}>
    <GlobalStyle />
    <NewApp />
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
