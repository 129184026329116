import React from "react";
import styled from "styled-components";

const InfoList = styled.ul`
  font-size: 1.3rem;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 1rem;
  }
`;

const Information = () => (
  <>
    <InfoList data-testid="infolist">
      <li>
        <h2>
          <span role="img" aria-label="kalender">
            🗓
          </span>{" "}
          Dato
        </h2>
        PLASK blir 7.mars 2024.<br/>
6.mars blir det pre-conference workshop: Zero Trust, med Microsoft.      </li>
      <li>
        <h2>
          <span role="img" aria-label="tidtaker">
            ⏲
          </span>{" "}
          Program
        </h2>
        <p>
          🗣️ Det blir talks 10-16 - Ingen påmelding
        </p>
        <p>
          🌊 Mageplask starter 16:00 - <u>Husk påmelding under</u> 📣
        </p>
        <p>
          <i>Ingen påmelding til konferansen. Gå direkte til den lyntalen du ønsker å se, men flytt minst mulig rundt når talene pågår.</i>
        </p>
      </li> 
      <li>
        <h2>
          <span role="img" aria-label="telefon">
            📞
          </span>{" "}
          Slack
        </h2>
        Ta kontakt på Slack i kanalen #plask om du har spørsmål.
      </li>
      <li>
        <h2>
          <span role="img" aria-label="telefon">
          👋
          </span>{" "}
          Påmelding Mageplask
        </h2>
        <p>MagePlask starter klokken 16 og er det sosiale eventet som skjer etter PLASK. Her inviterer vi til mat og drikke, samt gode diskusjoner av dagens læring.</p>
        <p><a href="https://forms.gle/CZka4kDHuqvYs6A28">Meld deg på her!</a></p>
      </li>
      <li>
        <h2>
          <span role="img" aria-label="kamera">
            📷
          </span>{" "}
          Bilder
        </h2>
        Se bildene fra forrige Plask-konferanse og følg oss på Instagram <a href="https://www.instagram.com/plask_konferanse/">@plask_konferanse</a>
      </li>
    </InfoList>
  </>
);

export default Information;
