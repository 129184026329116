import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NewAgenda from "./NewAgenda/NewAgenda";

import data2019 from "./2019.json";
import data2020 from "./2020.json";
import data2021 from "./2021.json";
import data2022 from "./2022.json";
import data2023 from "./2023.json";
import data2024 from "./2024.json";

const AgendaContainer = () => {
  const [scheduleData, setScheduleData] = useState();
  const [redirect, setRedirect] = useState();
  const [nextYear, setNextYear] = useState();
  const navigate = useNavigate();
  let { yearparam } = useParams();

  useEffect(() => {
    switch (yearparam) {
      case "2019":
        setScheduleData(data2019);
        break;
      case "2020":
        setScheduleData(data2020);
        break;
      case "2021":
        setScheduleData(data2021);
        break;
      case "2022":
        setScheduleData(data2022);
        break;
      case "2023":
        setScheduleData(data2023);
        break;
      case "2024":
        setScheduleData(data2024);
        break;
      case "2025":
        setNextYear(true);
        break;
      default:
        setRedirect(true);
        break;
    }
  }, [yearparam, setRedirect, setNextYear]);

  if (redirect) {
    return navigate("/404");
  }

  if (nextYear) {
    return <h1>Litt tidlig ute du?</h1>;
  }

  if (!scheduleData) {
    return null;
  }

  const { year, showTeamsLinks, tracks, slots, events } = scheduleData;

  return (
    <NewAgenda
      year={year}
      tracks={tracks}
      timeslots={slots}
      events={events}
      showTeamsLinks={showTeamsLinks}
    />
  );
};

export default AgendaContainer;
