import React from "react";
import styled from "styled-components";

const SpeakerInformation = styled.ul`
  font-size: 1.3rem;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 1rem;
  }
`;

const SpeakerInformationWrapper = () => (
  <SpeakerInformation data-testid="infolist">
    <i>
      <p>
        Kjære lyntalere og "tordentalere"
        <span role="img" aria-label="hjerte">
          ❤️
        </span>
      </p>
      <p>
        Tusen takk for at dere har meldt dere til å delta på årets utgave av
        Plask. Vi klarer ikke dette uten dere. Her følger litt teknisk og
        praktisk informasjon vi ber om at dere har lest gjennom på forhånd.
      </p>
      <p>Vi gleder oss! </p>
      <p>Mvh Plask-komitéen</p>
    </i>
    <hr />

    <h2>På forhånd:</h2>
    <ul>
      <li>
        Jobb med lyntalen din, og rekk ut en hånd til komitéen om du trenger
        hjelp eller råd.
      </li>
      <li>
        Det skjer alltid uforutsette ting i livet. Om du vet allerede nå eller
        oppdager snart at du ikke likevel kan holde en lyntale eller innlegg, MÅ
        du si fra til komiteen så fort du kan. Programmet blir mer og mer låst
        jo nærmere vi kommer dagen. Vi rekker ikke justere på PDF og
        nettside-versjon dersom du avlyser tett på.{" "}
      </li>
      <li>
        Lever presentasjonene i forkant til komitéen,{" "}
        <b>minimum 48 timer før</b>. Gjør du ikke dette begynner vi å mase og
        stresse de som ikke har levert. Vi har flere ganger røsket folk fra
        møter. Be warned!
      </li>
    </ul>

    <h2>På dagen:</h2>
    <ul>
      <li>Opplegget starter 11:45 med en liten introduksjon i auditoriet.</li>
      <li>
        Lyntalene er på 10 minutter med 5 minutter til neste programpost mellom.
        Innleggene er på 25 minutter med tilsvarende 5 min til neste.
      </li>
      <li>
        Vær forberedt og tilstede når det er din tid. Se i programmet når du
        skal ha din lyntale eller innlegg.
      </li>
      <li>
        Vi setter ut laptopper per møterom som inneholder alle de
        tilsendte/innsamlede presentasjonene. Du trenger derfor ikke ta med pc.
      </li>
      <li>
        Hvert rom vil ha en laptop for presentasjonen, en «clicker» for å gå til
        neste/forrige slide - og ikke minst – en vakt som hjelper til med
        oppkobling og passer tiden.
      </li>
      <li>
        Tidsplanen er ikke tilgivende, og man blir klappet av scenen når
        sluttidspunktet er der, om man begynte på tiden eller 9,5 minutter for
        seint.
      </li>
      <li>
        Ha en plan B! Demoer og «live koding» er utrolig bra når det virker, men
        vi anbefaler at man har skjermbilder i form av en presentasjon klar til
        fredag i tilfelle ting skjærer seg.{" "}
      </li>
      <li>
        Tenk gjennom timingen! Om man har maks 10 minutter og 120 slides blir
        det maks 5 sekunder per slide.{" "}
      </li>
      <li>
        Møterommene er innimellom vanskelige når det gjelder tilkobling av
        PC-er, spesielt om man bytter fra Mac- til Windows-maskin. Det er 5
        minutter mellom hver lyntale/innlegg. Vær gjerne klar et par minutter
        før start slik at ting begynner presist og «bare virker».
      </li>
    </ul>
  </SpeakerInformation>
);

export default SpeakerInformationWrapper;
