import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import data2019 from './2019.json';
import data2020 from './2020.json';
import data2021 from './2021.json';
import data2022 from './2022.json';
import data2023 from './2023.json';
import data2024 from './2024.json';
import NewAgenda from './NewAgenda/NewAgenda';

const Nav = styled.nav`
  text-align: center;
`;

const Button = styled.button`
  cursor: pointer;
  box-shadow: 0 0 1px 0;
  border: 0;
  border-radius: 4px;
  margin: 10px;
  padding: 4px;
  font-size: 1rem;

  &:hover, &:focus {
    background-color: #ccc;
    z-index: 0;
  }

  ${({ checked }) => checked && css`
    background-color: #ccc;
  `}
`;

export const Archive = () => {
  const [year, setYear] = useState();
  const [data, setData] = useState();

  useEffect(() => {
    switch (year) {
      case 2019:
        setData(data2019);
        break;
      case 2020:
        setData(data2020);
        break;
      case 2021:
        setData(data2021);
        break;
      case 2022:
        setData(data2022);
        break;
      case 2023:
        setData(data2023);
        break;
      case 2024:
        setData(data2024);
        break;
      default:
        break;
    }
  }, [year, setYear, setData]);

  return (
    <>
      <Nav>
        <Button checked={year === 2019} onClick={() => setYear(2019)}>
          2019
        </Button>
        <Button checked={year === 2020} onClick={() => setYear(2020)}>
          2020
        </Button>
        <Button checked={year === 2021} onClick={() => setYear(2021)}>
          2021
        </Button>
        <Button checked={year === 2022} onClick={() => setYear(2022)}>
          2022
        </Button>
        <Button checked={year === 2023} onClick={() => setYear(2023)}>
          2023
        </Button>
        <Button checked={year === 2024} onClick={() => setYear(2024)}>
          2024
        </Button>
      </Nav>
      {data && (
        <NewAgenda
          year={data.year}
          tracks={data.tracks}
          timeslots={data.slots}
          events={data.events}
          showTeamsLinks={data.showTeamsLinks}
        />
      )}
    </>
  );
};
