import styled from "styled-components";

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
padding-bottom: 5px;
  svg {
    margin-right: 1rem;
  }
`;
const StyledClock = styled.span`
padding-right: 5px;
`;

const clocks = {
  1000: "🕙",
  1015: "🕙",
  1030: "🕥",
  1045: "🕥",
  1100: "🕚",
  1115: "🕚",
  1130: "🕦",
  1145: "🕦",
  1200: "🕛",
  1215: "🕛",
  1230: "🕧",
  1245: "🕧",
  1300: "🕐",
  1315: "🕐",
  1330: "🕜",
  1345: "🕜",
  1400: "🕑",
  1415: "🕑",
  1430: "🕝",
  1445: "🕝",
  1500: "🕒",
  1515: "🕒",
  1530: "🕞",
  1545: "🕞",
  1600: "🕓"
};

const SlotHeader = ({ label }) => (
  <HeaderContainer>
    <StyledClock>{clocks[label.replace(":","")]}</StyledClock>
    <span>{label}</span>
  </HeaderContainer>
);

export default SlotHeader;