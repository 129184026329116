import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { Element, scroller } from "react-scroll";

const TrackName = styled.div``;
const TrackDescription = styled.div``;
const EventDetails = styled.div``;
const EventSpeaker = styled.div``;
const EventTitle = styled.h3``;
const EventDescription = styled.div``;
const EventTags = styled.div``;
const EventCellStatus = styled.div``;
const EventCellTitle = styled.div``;

const EventCellSpeaker = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 1px black;
  margin-top: 8px;
  font-size: 12px;
`;

function LightenDarkenColor(col, amt) {
  var usePound = false;

  if (col[0] === "#") {
    col = col.slice(1);
    usePound = true;
  }

  var num = parseInt(col, 16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

/* Grid - main container element for the css grid */
const Grid = styled.div`
  display: grid;
  align-items: start;
  text-align: center;
  grid-row-gap: 8px;
  grid-column-gap: 8px;

  ${(props) => css`
    grid-template-columns: repeat(${props.columnCount + 1}, auto);
    grid-template-rows: repeat(${props.rowCount + 1}, auto);
  `}
`;

/* ColumnHeader - column header for decorating columns */
const ColumnLabel = styled.div`
  grid-row: 1;
  padding: 0 3px;
  align-self: flex-start;
  align-content: flex-start;
  text-align: left;
  color: #444;
  font-weight: bold;
  font-size: 0.8rem;
  z-index: 10;
  ${(props) => css`
    grid-column: ${props.column};
  `}
`;
/* RowLabel */
const RowLabel = styled.div`
  grid-column: 1 / span 1;
  padding: 3px;
  justify-self: end;
  align-self: flex-start;
  align-content: flex-start;
  text-align: right;
  font-weight: bold;
  font-size: 0.8rem;
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 10;
  padding-right: 8px;
  background-image: linear-gradient(
    to left,
    rgba(243, 243, 243, 0.9),
    rgba(243, 243, 243, 0.95),
    rgb(243, 243, 243)
  );
  ${(props) => css`
    grid-row: ${props.row};
  `}
`;

/* Event - base content element for the grid */
const Event = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 1px 0 rgba(9, 30, 66, 0.25);
  color: #172b4d;
  padding: 10px;
  height: ${({ rowSpan }) => rowSpan * 100}%;
  min-width: 200px;
  border-radius: 8px;
  font-size: 1rem;
  z-index: 5;
  background-color: #e5e5e5;
  cursor: pointer;

  ${({ active, column, colSpan, row, status, bg }) => css`
    ${bg && "background-color: " + bg + ";"}
    ${active && "background-color: #d8d8d8;"}
    ${bg && active && "background-color: " + LightenDarkenColor(bg, -20) + ";"}
    grid-column: ${column} / span ${colSpan};
    grid-row: ${row};
    min-width: ${Math.min(colSpan, 2) * 200}px;
    ${EventCellTitle} {
      text-decoration: ${status === "cancelled" ? "line-through" : "initial"};
      color: ${status === "cancelled" ? "#888" : "#172b4d"};
    }

    &:hover {
      background-color: #d8d8d8;
      ${bg && "background-color: " + LightenDarkenColor(bg, -20) + ";"}
    }
  `}
`;

const BoxShadowContainer = styled.div`
  width: 100%;
  position: relative;
`;

const BoxShadow = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 12px;
  z-index: 10;
`;

const Tag = styled.span`
  background-color: #a6f5ff;
  color: #333;
  display: inline-block;
  padding: 4px 10px;
  border-radius: 4px;
  line-height: 20px;
  justify-content: center;
  align-content: center;
  margin-right: 12px;
`;

const Tags = ({ tags }) => {
  var mappedTags = tags.map((tag, index) => {
    return <Tag key={`tag${index}`}>{tag}</Tag>;
  });

  return mappedTags;
};

const TeamsLink = styled.a`
  padding: 0;
  font-size: 11pt;
  cursor: pointer;
  text-decoration: none;
  background-color: #7b83eb;
  color: white;
  padding: 8px 16px 8px 16px;
  margin: 10px;
  border-radius: 8px;

  &:focus {
    outline: 0;
  }

  &:hover {
    color: white;
    background-color: #464eb8;
  }
`;

const TeamsLinkBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  & img {
    margin: 10px;
  }
`;

const Agenda = ({
  className,
  year,
  tracks,
  timeslots,
  events,
  showTeamsLinks,
}) => {
  const [selectedEvent, setSelectedEvent] = useState();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [agenda, setAgenda] = useState();

  useEffect(() => {
    const cols = timeslots.map((slot, index) => (
      <ColumnLabel key={`collbl-${index}`} column={index + 2}>
        {slot.label}
      </ColumnLabel>
    ));

    const rows = tracks.map((track, index) => (
      <RowLabel key={`rowlbl-${index}`} row={index + 2}>
        <TrackName>{track.name}</TrackName>
        <TrackDescription>{track.guidance}</TrackDescription>
      </RowLabel>
    ));

    const cells = events.map((event, index) => {
      var global = event.trackId === 'all';
      var trackIndex = tracks.findIndex((t) => t.id === event.trackId);
      var track = global ? tracks[0] : tracks[trackIndex];
      var slotIndex = timeslots.findIndex((t) => t.id === event.slotId);

      return (
        <Event
          data-id={index}
          bg={event.background}
          active={selectedIndex === index}
          key={index}
          row={parseInt(track.id) + 1}
          column={slotIndex + 2}
          colSpan={event.size}
          rowSpan={global ? tracks.length - 1 : 1}
          trackBackgroundColor={track.bg}
          trackTextColor={track.color}
          status={event.status}
          onClick={() => {
            setSelectedEvent(event);
            setSelectedIndex(index);
            if (window && window.innerWidth < 768) {
              scroller.scrollTo("event-details", {
                duration: 500,
                delay: 0,
                smooth: "easeInOutQuart",
              });
            } else {
              scroller.scrollTo("event-grid", {
                duration: 1000,
                delay: 0,
                smooth: "easeInOutQuart",
              });
            }
          }}
        >
          <EventCellTitle>{event.title}</EventCellTitle>
          {event.status === "cancelled" && (
            <EventCellStatus>avlyst</EventCellStatus>
          )}
          <EventCellSpeaker>{event.speaker}</EventCellSpeaker>
        </Event>
      );
    });

    const total = rows.concat(cols.concat(cells));

    setAgenda(total);
  }, [
    events,
    timeslots,
    tracks,
    setSelectedIndex,
    setSelectedEvent,
    selectedIndex,
  ]);

  if (!agenda) {
    return <h1>...</h1>;
  }

  return (
    <div className={className}>
      <Element name="event-grid">
        <h1>
          Program {year}{" "}
          {/* <a href={`/plask-program-${year}.pdf`} download>
            (PDF)
          </a> */}
        </h1>
        <div></div>
        {showTeamsLinks && (
          <TeamsLinkBox>
            <div>
              <img src="/teams-icon.png" height="48" alt="teams-logo" />
            </div>
            <div>
              <TeamsLink href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_MDgzZDlhNzUtZDFiZC00ZGY5LWIxMWMtYzAyYTI5NWRmMzhl%40thread.v2/0?context=%7b%22Tid%22%3a%22417918a5-f594-44e0-ba27-23112940bde5%22%2c%22Oid%22%3a%22f05573df-66b5-4b17-8855-d9295f930777%22%7d">
                Spor 1
              </TeamsLink>
            </div>
            <div>
              <TeamsLink href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_MWM2NTk2ZWQtMDBlZi00ZTg4LTkzNDItYjNlOGVhN2YwYWYx%40thread.v2/0?context=%7b%22Tid%22%3a%22417918a5-f594-44e0-ba27-23112940bde5%22%2c%22Oid%22%3a%22f05573df-66b5-4b17-8855-d9295f930777%22%7d">
                Spor 2
              </TeamsLink>
            </div>
            <div>
              <TeamsLink href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_Nzg1YWZmODktMmU4My00MjY1LTgzZjYtNDhkOGMwOTA0ZmJi%40thread.v2/0?context=%7b%22Tid%22%3a%22417918a5-f594-44e0-ba27-23112940bde5%22%2c%22Oid%22%3a%22f05573df-66b5-4b17-8855-d9295f930777%22%7d">
                Spor 3
              </TeamsLink>
            </div>
            <div>
              <TeamsLink href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_NGUxYWY0MDgtNzVjYy00MDFjLThkMTEtODU5MzA1Yzg4ZjM5%40thread.v2/0?context=%7b%22Tid%22%3a%22417918a5-f594-44e0-ba27-23112940bde5%22%2c%22Oid%22%3a%22f05573df-66b5-4b17-8855-d9295f930777%22%7d">
                Spor 4
              </TeamsLink>
            </div>
          </TeamsLinkBox>
        )}
        <BoxShadowContainer>
          <BoxShadow />
          <Grid columnCount={timeslots.length} rowCount={tracks.length}>
            {agenda}
          </Grid>
        </BoxShadowContainer>
      </Element>
      <Element name="event-details">
        {selectedEvent && (
          <EventDetails>
            <EventTitle>{selectedEvent.title}</EventTitle>
            <EventSpeaker>
              {/* <img
                src={`https://api.adorable.io/avatars/128/${selectedEvent.speaker}@plask.png`}
                alt="profile"
              /> */}
              {selectedEvent.speaker}
            </EventSpeaker>

            <EventDescription
              dangerouslySetInnerHTML={{ __html: selectedEvent.description }}
            />

            <EventTags>
              <Tags tags={selectedEvent.type.split(";")} />
            </EventTags>
          </EventDetails>
        )}
      </Element>
    </div>
  );
};

export default styled(Agenda)`
  width: 100%;
  -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */

  ${EventCellStatus} {
    color: black;

    &::before {
      content: "❌ ";
    }
  }

  h1 {
    border-bottom: 4px dotted #ddd;
    padding-bottom: 8px;
    margin-bottom: 24px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  ${Grid} {
    padding-top: 20px;
    overflow: auto;
    margin-bottom: 32px;
  }

  ${EventDetails} {
    height: 100vh;
    border-top: 4px dotted #ddd;
    padding: 10px;
    margin: 0 auto;
    margin-bottom: 24px;

    ${EventTitle} {
      font-size: 1.5rem;
      margin-bottom: 12px;
    }

    ${EventDescription} {
      margin-bottom: 12px;
    }

    ${EventSpeaker} {
      color: #666;
      display: flex;
      align-items: center;
      font-size: 1.15rem;
      margin-bottom: 12px;
      font-weight: 900;

      img {
        border-radius: 50%;
        width: 80px;
        margin-right: 24px;
      }
    }
  }
`;
