import React, { useState } from "react";
import { isMobile } from 'react-device-detect';
import styled from "styled-components";

const Content = styled.div`
  display: flex;
  gap: 20px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  box-shadow: 1px 1px 2px rgba(0,0,0,0.2);

  :not(:last-child) {
    margin-bottom: 20px;
  }
`;

const EventTitle = styled.p`
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 0;
`;

const Speaker = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 1rem;
`;

const ReadmoreButton = styled.button`
  background-color: transparent;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  color: #416787;
  font-size: 1rem;
  font-family: inherit;
  padding: 0;
  margin-left: 5px;
`;

const Location = styled.p`
  font-size: 14px;
  font-weight: 600;
`;

const EventTypeBox = styled.span`
  display: inline-block;
  height: 24px;
  line-height: 24px;
  position: relative;
  margin: 0 16px 8px 0;
  padding: 0 10px 0 12px;
  -webkit-border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #777;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  color: #333;
  font-size: 12px;
  font-family: "Lucida Grande","Lucida Sans Unicode",Verdana,sans-serif;
  text-decoration: none;
  text-shadow: 0 1px 2px rgba(0,0,0,0.2);
  font-weight: bold;
`;

const trimIt = (desc, limit) => `${desc.substring(0, limit)} ... `; 

const ReadMore = ({ description = '' }) => {
  const limit = 50;
  const tooLongDesc = description.trim().length > limit && !isMobile;
  const [showReadMore, setShowReadMore] = useState(tooLongDesc);
  const descriptionToShow = showReadMore ? trimIt(description, limit) : description;

  return (
    <p>
      {descriptionToShow}
      {tooLongDesc && (
          <ReadmoreButton onClick={() => setShowReadMore(!showReadMore)}>
            {showReadMore ? 'Vis mer' : 'Vis mindre'}
          </ReadmoreButton>
      )}
    </p>
  )
};


const EventType = ({ type }) => {
 
  return (
    <EventTypeBox>{(type.length > 0 ? type:"")}</EventTypeBox>
  )
};
const Event = ({ event, tracks }) => {
  const track = tracks.find(track => track.id === event.trackId);
  var typeBackground = "transparent";
  switch (event.type) {
    case "Utvikling":
      typeBackground = "#c2f7ff";
      break;
    case "UX":
      typeBackground = "#dcedfc";
      break;
    case "Annet/Usikker":
      typeBackground = "#dd7e6b";
      break;
    case "Metode":
      typeBackground = "#f0ebfc";
      break;
    case "Produktutvikling":
      typeBackground = "#fff2cc";
      break;
    case "AI/MLDATA":
      typeBackground = "#fce5cd";
      break
    case "QA/Sikkerhet":
      typeBackground = "#fae3ed";
      break;
    case "Keynote":
      typeBackground = "#cdf7e3";
      break;
    case "UU":
      typeBackground = "#e9dcfc";
      break;
    case "DIY":
      typeBackground = "#abf4b2";
      break;
    case "Samfunn":
      typeBackground = "#facdd1";
      break;
    case "Helse":
      typeBackground = "#f7dce7";
      break; 
    case "Pause":
      typeBackground = "#fafafa";
      break;
    case "🎉 Sosialt 🎉":
      typeBackground = "#00FFFF";
      break;
    default:
      break;
  }

  const TextDiv = (event.title.includes("AVLYST") ? styled.div`filter: blur(10px);` : styled.div``);

  const EventTypeBoxBackground = {
    background: typeBackground
  };
  return (
  <Content style={EventTypeBoxBackground}>
    <div>
      <EventTitle>{event.title}</EventTitle>
      <Speaker>{event.speaker}</Speaker>
      <TextDiv>
        <ReadMore description={event.description} />
      </TextDiv>
      <Location>{track?.name} {track?.guidance}</Location>
      <EventType type={event.type}></EventType>  
    </div>
  </Content>
)
};

export default Event;