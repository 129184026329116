import React from "react";
import styled from "styled-components";

const Categories = ({ className }) => (
  <ul className={className}>
    <li>
      <span role="img" aria-label="Detektiv">
        🕵️‍♀️
      </span>{" "}
      Test
    </li>
    <li>
      <span role="img" aria-label="Graf">
        📈
      </span>{" "}
      Analyse og Innsikt
    </li>
    <li>
      <span role="img" aria-label="Hus">
        🏡
      </span>{" "}
      Arkitektur
    </li>
    <li>
      <span role="img" aria-label="Hammer og skiftenøkkel">
        🛠
      </span>{" "}
      Verktøy
    </li>
    <li>
      <span role="img" aria-label="Person roing">
        🚣‍♀️
      </span>{" "}
      Prosess
    </li>
    <li>
      <span role="img" aria-label="Personer som danser med kaninører">
        👯‍♀️
      </span>{" "}
      Team
    </li>
    <li>
      <span role="img" aria-label="Rakett">
        🚀
      </span>{" "}
      DevOps
    </li>
    <li>
      <span role="img" aria-label="Rakett">
        💅
      </span>{" "}
      Ux og Tjenestedesign
    </li>
  </ul>
);

export default styled(Categories)`
  list-style: none;
  padding: 0;
  margin: 0;
`;
