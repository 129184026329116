import Event from "./Event";

const SlotEvents = ({ slotId, events, tracks }) => (
  <>
  {events
    .filter(event => event.slotId === slotId)
    .map(event => <Event event={event} tracks={tracks}/>)}
  </>
);

export default SlotEvents;