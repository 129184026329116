import styled from "styled-components";
import { Accordion } from "@sparebankenvest/splash-ui";
import SlotHeader from "./components/SlotHeader";
import SlotEvents from "./components/SlotEvents";

const StyledAccordion = styled(Accordion)`
  width: 100%;
  margin: 30px 0;

  li:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

const mapAccordionItems = (slots, events, tracks) =>
  slots.map(({ id, label }) => ({
    id,
    header: <SlotHeader label={label} />,
    content: <SlotEvents slotId={id} events={events} tracks={tracks} />
  }));

const Mobile = ({timeslots, events, tracks})=>{
  return(
    <StyledAccordion 
      initialOpenItemId="0830"
      hasScrollIntoView="true"
      items={mapAccordionItems(timeslots, events, tracks)}/>
  )
};

export default Mobile;
