import { isMobile } from 'react-device-detect';
import Mobile from "./Mobile";
import Desktop from './Desktop';

const NewAgenda = ({ timeslots, events, tracks })=>{
  return(
    <>
      {isMobile
      ? <Mobile timeslots={timeslots} events={events} tracks={tracks} />
      : <Desktop timeslots={timeslots} events={events} tracks={tracks} />}
    </>
  )
};

export default NewAgenda;
