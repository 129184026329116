import styled from "styled-components";
import SlotEvents from "./components/SlotEvents";
import SlotHeader from "./components/SlotHeader";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(275px, 1fr) );
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 50px 0 50px 0;
`;

const Slot = styled.div`
  background-color: #FFFFFF;
  padding: 10px;
  border-radius: 5px;
`;

const Desktop = ({ timeslots, events, tracks })=>{
  return (
    <Grid>
    {timeslots.map(({ id, label }) => (
      <Slot>
        <SlotHeader label={label} />
        <SlotEvents slotId={id} events={events} tracks={tracks} />
      </Slot>
    ))}
    </Grid>
  );
};

export default Desktop;