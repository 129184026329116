import React from "react";
import styled from "styled-components";
import Lottie from "react-lottie";

var animationData = {
  v: "5.6.5",
  fr: 60,
  ip: 0,
  op: 240,
  w: 734,
  h: 633,
  nm: "Plask-logo",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "period Outlines",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0]
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 5,
              s: [100]
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 198,
              s: [100]
            },
            { t: 212, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [367, 143.5, 0],
              to: [0, 28.833, 0],
              ti: [0, -28.833, 0]
            },
            { t: 20, s: [367, 316.5, 0] }
          ],
          ix: 2,
          x:
            "var $bm_rt;\nvar e, g, nMax, n, n, t, v, vl, vu, vu, tCur, segDur, tNext, nb, delta;\ne = 0.75;\ng = 2000;\nnMax = 9;\n$bm_rt = n = 0;\nif (numKeys > 0) {\n    $bm_rt = n = nearestKey(time).index;\n    if (key(n).time > time)\n        n--;\n}\nif (n > 0) {\n    t = $bm_sub(time, key(n).time);\n    v = $bm_mul($bm_neg(velocityAtTime($bm_sub(key(n).time, 0.001))), e);\n    vl = length(v);\n    if ($bm_isInstanceOfArray(value)) {\n        vu = vl > 0 ? normalize(v) : [\n            0,\n            0,\n            0\n        ];\n    } else {\n        vu = v < 0 ? -1 : 1;\n    }\n    tCur = 0;\n    segDur = $bm_div($bm_mul(2, vl), g);\n    tNext = segDur;\n    nb = 1;\n    while (tNext < t && nb <= nMax) {\n        vl *= e;\n        segDur *= e;\n        tCur = tNext;\n        tNext = $bm_sum(tNext, segDur);\n        nb++;\n    }\n    if (nb <= nMax) {\n        delta = $bm_sub(t, tCur);\n        $bm_rt = $bm_sum(value, $bm_mul($bm_mul(vu, delta), $bm_sub(vl, $bm_div($bm_mul(g, delta), 2))));\n    } else {\n        $bm_rt = value;\n    }\n} else\n    $bm_rt = value;"
        },
        a: { a: 0, k: [367, 316.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-7.732, 0],
                    [0, -7.732],
                    [7.732, 0],
                    [0, 7.732]
                  ],
                  o: [
                    [7.732, 0],
                    [0, 7.732],
                    [-7.732, 0],
                    [0, -7.732]
                  ],
                  v: [
                    [0, -14],
                    [14, 0],
                    [0, 14],
                    [-14, 0]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.039000002543, 0.885999971278, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [664, 229], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "text Outlines",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 0,
              s: [367, 316.5, 0],
              to: [0, 0.583, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 30,
              s: [367, 320, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 60,
              s: [367, 316.5, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 90,
              s: [367, 320, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 120,
              s: [367, 316.5, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 150,
              s: [367, 320, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 180,
              s: [367, 316.5, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 210,
              s: [367, 320, 0],
              to: [0, 0, 0],
              ti: [0, 0.583, 0]
            },
            { t: 239, s: [367, 316.5, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [367, 316.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [5.28, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.28, 1.08],
                    [0, 3.96],
                    [0, 0],
                    [2.4, 1.08]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [5.28, 0],
                    [2.4, -1.08],
                    [0, 0],
                    [0, -3.84],
                    [-2.28, -1.08]
                  ],
                  v: [
                    [-4.14, -32.94],
                    [-14.04, -32.94],
                    [-14.04, 0.36],
                    [-4.14, 0.36],
                    [7.2, -1.26],
                    [10.8, -8.82],
                    [10.8, -23.94],
                    [7.2, -31.32]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ind: 1,
              ty: "sh",
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [8.16, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -33.24],
                    [1.44, -6.36],
                    [3.48, -4.8],
                    [5.52, -2.76]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [30.96, 0],
                    [0, 7.32],
                    [-1.44, 6.36],
                    [-3.36, 4.68],
                    [-5.4, 2.76]
                  ],
                  v: [
                    [14.04, 34.92],
                    [-14.04, 34.92],
                    [-14.04, 67.5],
                    [-57.24, 67.5],
                    [-57.24, -67.5],
                    [10.8, -67.5],
                    [57.24, -17.64],
                    [55.08, 2.88],
                    [47.7, 19.62],
                    [34.38, 30.78]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 2",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "mm",
              mm: 1,
              nm: "Merge Paths 1",
              mn: "ADBE Vector Filter - Merge",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [0, 0.238999998803, 0.698000021542, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [144.04, 173.5], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.8, -0.24],
                    [-1.2, -0.12],
                    [0, 0],
                    [6, 2.16],
                    [2.64, 2.88],
                    [0, 5.4]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [2.28, 0.48],
                    [1.8, 0.24],
                    [0, 0],
                    [-18.36, 0],
                    [-5.04, -1.8],
                    [-2.52, -2.88],
                    [0, 0]
                  ],
                  v: [
                    [-26.91, -69.75],
                    [16.29, -72.99],
                    [16.29, 39.69],
                    [22.41, 40.77],
                    [26.91, 41.31],
                    [24.93, 72.99],
                    [-11.61, 69.75],
                    [-23.13, 62.73],
                    [-26.91, 50.31]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [0, 0.238999998803, 0.698000021542, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [241.151, 170.35], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 2",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -3.96],
                    [0, 0],
                    [0, 0],
                    [-1.92, 1.44],
                    [0, 2.16],
                    [0, 0],
                    [0, 0],
                    [2.4, -1.08]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [6.24, 0],
                    [1.68, -1.56],
                    [0, 0],
                    [0, 0],
                    [-5.16, 0],
                    [-2.4, 1.08]
                  ],
                  v: [
                    [-11.7, 16.92],
                    [-11.7, 25.74],
                    [-2.7, 25.74],
                    [9.54, 23.58],
                    [12.06, 18],
                    [12.06, 7.74],
                    [3.24, 7.74],
                    [-8.1, 9.36]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ind: 1,
              ty: "sh",
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-18.84, 0],
                    [0, -23.76],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [3, -1.92],
                    [3.36, -1.32],
                    [3.48, -0.72],
                    [3.24, 0],
                    [4.92, 1.32],
                    [3.36, 2.76],
                    [1.8, 4.56],
                    [0, 6.6],
                    [-39.12, 0],
                    [0, 0],
                    [0, 0],
                    [1.92, 0.12],
                    [1.56, 0],
                    [11.76, -3.96],
                    [0, 0]
                  ],
                  o: [
                    [34.56, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.16, 2.4],
                    [-2.88, 1.92],
                    [-3.36, 1.32],
                    [-3.48, 0.72],
                    [-6.12, 0],
                    [-4.8, -1.2],
                    [-3.36, -2.76],
                    [-1.8, -4.56],
                    [0, -25.92],
                    [0, 0],
                    [0, 0],
                    [-2.28, -0.12],
                    [-1.8, -0.12],
                    [-16.2, 0],
                    [0, 0],
                    [14.4, -4.44]
                  ],
                  v: [
                    [3.42, -57.24],
                    [55.26, -21.6],
                    [55.26, 54],
                    [33.66, 54],
                    [21.42, 41.76],
                    [13.68, 48.24],
                    [4.32, 53.1],
                    [-5.94, 56.16],
                    [-16.02, 57.24],
                    [-32.58, 55.26],
                    [-44.82, 49.32],
                    [-52.56, 38.34],
                    [-55.26, 21.6],
                    [3.42, -17.28],
                    [12.06, -17.28],
                    [12.06, -25.02],
                    [5.76, -25.38],
                    [0.72, -25.56],
                    [-41.22, -19.62],
                    [-46.44, -50.58]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 2",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "mm",
              mm: 1,
              nm: "Merge Paths 1",
              mn: "ADBE Vector Filter - Merge",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [0, 0.238999998803, 0.698000021542, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [328.918, 187], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 3",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-9.36, 5.04],
                    [-16.08, 0],
                    [-13.439, -4.44],
                    [0, 0],
                    [5.52, 0.72],
                    [5.76, 0],
                    [2.521, -0.12],
                    [3.119, -0.36],
                    [0, 0],
                    [-6.96, -0.72],
                    [-5.639, -2.52],
                    [-3.599, -4.92],
                    [0, -8.4],
                    [1.319, -4.68],
                    [2.76, -2.76],
                    [3.12, -1.68],
                    [4.92, -0.72],
                    [4.44, -0.24],
                    [5.04, 0],
                    [14.64, 4.2],
                    [0, 0],
                    [-6.481, -0.96],
                    [-7.08, 0],
                    [-2.879, 0.12],
                    [-2.761, 0.36],
                    [0, 0],
                    [7.799, 1.44],
                    [4.8, 3.12],
                    [2.04, 5.04],
                    [0, 6.96]
                  ],
                  o: [
                    [9.24, -5.04],
                    [16.201, 0],
                    [0, 0],
                    [-5.639, -1.68],
                    [-5.52, -0.72],
                    [-1.68, 0],
                    [-2.399, 0.12],
                    [0, 0],
                    [6.6, 0],
                    [6.96, 0.6],
                    [5.761, 2.4],
                    [3.721, 4.8],
                    [0, 5.88],
                    [-1.32, 4.56],
                    [-2.88, 2.76],
                    [-3, 1.68],
                    [-3.84, 0.6],
                    [-4.32, 0.36],
                    [-17.641, 0],
                    [0, 0],
                    [6.12, 1.8],
                    [6.479, 0.96],
                    [3.24, 0],
                    [3, -0.12],
                    [0, 0],
                    [-11.52, 0],
                    [-7.68, -1.56],
                    [-4.68, -3.12],
                    [-1.92, -5.04],
                    [0, -13.92]
                  ],
                  v: [
                    [-36.09, -49.68],
                    [1.889, -57.24],
                    [46.349, -50.58],
                    [41.309, -21.24],
                    [24.57, -24.84],
                    [7.65, -25.92],
                    [1.349, -25.74],
                    [-6.93, -25.02],
                    [-6.93, -18.18],
                    [13.41, -17.1],
                    [32.309, -12.42],
                    [46.349, -1.44],
                    [51.93, 18.36],
                    [49.95, 34.2],
                    [43.83, 45.18],
                    [34.83, 51.84],
                    [22.95, 55.44],
                    [10.53, 56.7],
                    [-3.51, 57.24],
                    [-51.93, 50.94],
                    [-46.53, 20.34],
                    [-27.63, 24.48],
                    [-7.29, 25.92],
                    [1.889, 25.74],
                    [10.53, 25.02],
                    [10.53, 18.18],
                    [-18.45, 16.02],
                    [-37.17, 9],
                    [-47.25, -3.24],
                    [-50.13, -21.24]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [0, 0.238999998803, 0.698000021542, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [449.912, 187], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 4",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: "ADBE Vector Group",
          hd: false
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [54, 71.82],
                    [27.899, 15.3],
                    [52.92, -36.18],
                    [6.3, -36.18],
                    [-9.36, -0.54],
                    [-10.801, -0.54],
                    [-10.801, -71.82],
                    [-54, -68.58],
                    [-54, 71.82],
                    [-10.801, 71.82],
                    [-10.801, 28.98],
                    [-9.36, 28.98],
                    [7.38, 71.82]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [0, 0.238999998803, 0.698000021542, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [570.938, 169.18], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 5",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "droplet Outlines",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [367, 316.5, 0], ix: 2 },
        a: { a: 0, k: [367, 316.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-12.15, 0],
                    [0, -12.15],
                    [12.15, 0],
                    [0, 12.15]
                  ],
                  o: [
                    [12.15, 0],
                    [0, 12.15],
                    [-12.15, 0],
                    [0, -12.15]
                  ],
                  v: [
                    [0, -22],
                    [22, 0],
                    [0, 22],
                    [-22, 0]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.039000002543, 0.885999971278, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [467.429, 532], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "wave Outlines",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -2.143, ix: 10 },
        p: { a: 0, k: [367, 318.707, 0], ix: 2 },
        a: { a: 0, k: [367, 316.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [179.2, 0],
                    [24.833, -48.333],
                    [-18.8, 0],
                    [-4, 6.5],
                    [-78.4, 4.4],
                    [-7.5, -5.5],
                    [-25.5, 5.5],
                    [3.667, 10.167],
                    [-0.399, 8],
                    [-15, -5],
                    [-5.833, -8.5],
                    [-38.399, 6],
                    [6.667, 15.167]
                  ],
                  o: [
                    [-179.2, 0],
                    [-4.833, 9.833],
                    [18.8, 0],
                    [21.833, -20.167],
                    [98, -5.5],
                    [7.5, 5.5],
                    [20.4, -4.4],
                    [-5, -6.5],
                    [0.5, -10],
                    [12, 4],
                    [4.833, 13.5],
                    [38.4, -6],
                    [-9.667, -45]
                  ],
                  v: [
                    [22.45, -108],
                    [-276.05, 37],
                    [-267.05, 66.5],
                    [-231.55, 47],
                    [-68.05, -19],
                    [78.45, 85.5],
                    [122.45, 102.5],
                    [136.95, 66.5],
                    [122.45, 37],
                    [152.45, 16.5],
                    [184.95, 47],
                    [247.45, 80],
                    [275.45, 27]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.039000002543, 0.885999971278, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [351.479, 378.5], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

const Logo = ({ className }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div className={className}>
      <Lottie options={defaultOptions} isClickToPauseDisabled={true} />
    </div>
  );
};

export default styled(Logo)``;
